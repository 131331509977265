(function () {
    'use strict';

    angular.module('aerosApp').factory('aflGridFilterFactory', aflGridFilterFactory);

    var activeHidden = {
            'Active': function (r) {
                return r.active === true;
            },
            'Hidden': function (r) {
                return !r.active;
            },
            'Active and Hidden': showAll,
            _default: 'Active'
        },
        legacyToolFilters = {
            toolLibrary: {'Active/Hidden': activeHidden},
            toolPreset: {
                'Active/Hidden': activeHidden,
                'Custom/Standard': {
                    'All': showAll,
                    'Custom Only': function (r) {
                        return r.isCustomTestLimit === true
                    },
                    'Standard Only': function (r) {
                        return r.isCustomTestLimit !== true
                    },
                    _default: 'All'
                },
                'ORL/OLTS': {
                    'ORL and OLTS': showAll,
                    'ORL Only': function (r) {
                        return r.lossThresholdType === 'ORLLoss'
                    },
                    'OLTS Only': function (r) {
                        return r.lossThresholdType !== 'ORLLoss'
                    },
                    _default: 'ORL and OLTS'
                }
            }
        },
        OLTSToolFilters = {
            toolLibrary: {'Active/Hidden': activeHidden},
            toolPreset: {
                'Active/Hidden': activeHidden,
                'Custom/Standard': {
                    'Custom Only': function (r) {
                        return r.isCustomTestLimit === true
                    },
                    _default: 'Custom Only'
                },
                'ORL/OLTS': {
                    'ORL and OLTS': showAll,
                    'ORL Only': function (r) {
                        return r.lossThresholdType === 'ORLLoss'
                    },
                    'OLTS Only': function (r) {
                        return r.lossThresholdType !== 'ORLLoss'
                    },
                    _default: 'ORL and OLTS'
                }
            }
        },
        newToolFilters = {tools: {}, preset: {}},
        FILTERS = {
            OLTS: OLTSToolFilters,
            Certification: legacyToolFilters,
            Inspection: legacyToolFilters,
            OTDR: newToolFilters,
            All: {toolLibrary: {}}
        };

    function aflGridFilterFactory() {
        return function (toolType, section) {
            return {
                getFilters: getFilters,
                getDefaults: getDefaults,
                doesItPass: doesItPass
            };

            function doesItPass(values) {
                return function (node) {
                    return _.chain(values).keys().map(mapFilters).every(function (f) {
                        return !f || f(node.data);
                    }).value();
                };

                function mapFilters(key) {
                    return FILTERS[toolType][section][key][values[key]];
                }
            }

            function getFilters() {
                return _.mapValues(FILTERS[toolType][section], function (val) {
                    return _.without(_.keys(val), '_default');
                });
            }

            function getDefaults() {
                return _.mapValues(FILTERS[toolType][section], function (filters) {
                    return filters._default;
                });
            }
        };
    }

    function showAll() {
        return true;
    }
}());
